<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <DeviceSelect v-if="!device" :device-group-id="groupId" @device="onDeviceSelected" />
      <DeviceModify v-if="device" :device-group-id="groupId" :device="device" />
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import AdminExtension from '../AdminExtension'
import DeviceSelect from '../device/DeviceSelect'
import DeviceModify from '../device/DeviceModify'

export default AdminExtension.extend({
    name: 'DeviceGroupDeviceAdd',
    props: {
        groupId: {
            type: String,
            require: false,
        }
    },
    components: {
      DeviceModify,
      DeviceSelect,
    },
    data() {
        return {
          isLocked: false,
          isSaved: true,
          form: new Form({
                  id: null,
                  consistency_token: null,
                  code: null,
                  uuid: null,
                  name: null,
                  expiration_date: null,
              }),
          deviceGroupId: null,
          device: null,
          isEdit: false,
          isChange: false,
          confirmPassword: null,
        }
    },
    computed: {
      passwordValidation(){
        return this.form.password == this.confirmPassword
      },
    },
    methods: {
        ...mapActions("common", ["generateCode"]),
        ...mapActions("meds2go/device", ["deviceCreate","deviceUpdate","deviceRemove", "getDeviceGroups"]),
        loadData(){
            if (this.groupId){
                this.onReset()
                this.deviceGroupId = this.groupId
            }else{
              this.generateCode({generate_code:'device'}).then(response=> {
                this.form.code = response
              })
            }
        },
        onReset(){
            this.form.reset()
        },
        onChange(){
            this.isChange = true
        },
        removeDevice(){
            this.deviceRemove({id: this.form.id, consistency_token: this.form.consistency_token, status: false}).
              then(this.$router.push("/dashboard")).catch(errors => {
                    this.form.errors.record(errors)
            })
        },
        onDeviceSelected(device){
          this.device = device
        },
    },
    mounted() {
        this.loadData()
    }
})
</script>

